/*
Template Name: Velzon - Admin & Dashboard Template
Author: Themesbrand
Website: https://themesbrand.com/
Contact: support@themesbrand.com
File: Custom Css File
*/
html::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}
html::-webkit-scrollbar {
  width: 3px;
  background-color: #f5f5f5;
}
html::-webkit-scrollbar-thumb {
  background-color: #555;
}

.mfp-bg {
  z-index: 1060 !important;
}

.mfp-wrap {
  z-index: 1100 !important;
}

.modal-open .main-content,
.modal-open .app-menu,
.modal-open #page-topbar {
  -webkit-filter: blur(3px);
  -moz-filter: blur(3px);
  -o-filter: blur(3px);
  -ms-filter: blur(3px);
  filter: blur(3px);
}

.modal::-webkit-scrollbar-track,
.modal-body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}
.modal::-webkit-scrollbar,
.modal-body::-webkit-scrollbar {
  width: 3px;
  background-color: #f5f5f5;
}
.modal::-webkit-scrollbar-thumb,
.modal-body::-webkit-scrollbar-thumb {
  background-color: #555;
}

[data-layout-mode=dark] .modal-content {
  background-color: #1a1d21;
}
[data-layout-mode=dark] .ztree a {
  color: #fff;
}

[data-layout-mode=light] .modal-content {
  background-color: #f3f3f9;
}
[data-layout-mode=light] .app-search .form-control {
  border: 1px solid var(--vz-input-border) !important;
}
[data-layout-mode=light] .navbar-menu {
  background-color: #fff !important;
  border: none !important;
}
[data-layout-mode=light] .navbar-menu .nav-link {
  color: #333 !important;
}
[data-layout-mode=light] .navbar-menu .nav-link svg {
  color: #333 !important;
}
[data-layout-mode=light] .navbar-menu .nav-link.active {
  color: #000 !important;
}
[data-layout-mode=light] .navbar-menu .nav-link :hover {
  color: #000 !important;
}
[data-layout-mode=light] .form-control {
  background-color: #fff !important;
}
[data-layout-mode=light] .ztree a {
  color: #000;
}

.validation-summary-errors ul {
  margin-bottom: 0;
  padding-left: 0;
}
.validation-summary-errors ul li {
  display: block;
}

.form-group {
  margin-bottom: 1rem;
}
.form-group.input-validation-error {
  border-color: #f06548;
  padding-right: calc(1.5em + 1rem);
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.25rem) center;
  background-size: calc(0.75em + 0.5rem) calc(0.75em + 0.5rem);
}
.form-group .field-validation-error {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #f06548;
}
.form-group .select2-container {
  display: block;
}

#filter-bar .select2 {
  width: 100% !important;
}
@media (min-width: 576px) {
  #filter-bar .select2 {
    width: 200px !important;
  }
}

.form-file {
  position: relative;
}
.form-file input {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.card-footer {
  text-align: center;
}

.hide {
  display: none !important;
}

/* width */
.w-xxs {
  width: 60px !important;
}

.w-xs {
  width: 90px !important;
}

.w-sm {
  width: 120px !important;
}

.w-md {
  width: 200px !important;
}

.w-lg {
  width: 240px !important;
}

.w-xl {
  width: 280px !important;
}

.w-xxl {
  width: 320px !important;
}

.w-24 {
  width: 24px !important;
}

.w-36 {
  width: 36px !important;
}

.w-48 {
  width: 48px !important;
}

.w-64 {
  width: 64px !important;
}

.w-72 {
  width: 72px !important;
}

.w-96 {
  width: 96px !important;
}

.w-128 {
  width: 128px !important;
}

.w-136 {
  width: 136px !important;
}

.w-144 {
  width: 144px !important;
}

.w-152 {
  width: 152px !important;
}

.w-min-content {
  width: min-content !important;
}

.w-max-content {
  width: max-content !important;
}

.fs-32 {
  font-size: 32px !important;
}

.card.selected {
  border: 2px #ff9500 solid !important;
}

.img-responsive {
  width: 100%;
  height: auto;
}

.img-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.input-group {
  flex-wrap: nowrap !important;
}

.select2-container .select2-selection--multiple .select2-selection__choice {
  padding: 3px 10px 3px 3px;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  background: #fff;
  color: #000;
}

.auth-one-bg-position {
  height: auto;
}

.be-mode {
  position: fixed;
  z-index: 1005;
}
.be-mode .mode-text {
  position: fixed;
  left: 50%;
  transform: translate(-50%, 0%);
  padding: 0.6rem;
  border: 1px solid var(--vz-border-color);
  border-radius: 0 0 10px 10px;
}